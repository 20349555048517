import { useSelector } from "@xstate/react";
import React, { useContext } from "react";
import { QueryParamProvider } from "use-query-params";
import { SEOReactHelmet } from "../../components/SEO/SEOReactHelmet";
import { isBrowser } from "../../utils/ssr";
import FilterHeader from "./components/FilterHeader";
import SearchInput from "./components/SearchInput";
import SearchResult from "./components/SearchResult";
import { Tabs } from "./components/Tabs";
import { SearchPageStateContext, SearchPageStateProvider } from "./SearchPageStateProvider";
import { useSearchTranslations } from "./translations";
import { SearchMachineState } from "./types";

const SearchPage = () => {
  if (!isBrowser()) return null;

  return (
    <SearchPageStateProvider>
      <View />
    </SearchPageStateProvider>
  );
};

// ts-prune-ignore-next
export default SearchPage;

const isRecipesTabSelector = (state: SearchMachineState) => state.matches("recipes.recipeView");
const totalRecipesSelector = (state: SearchMachineState) => state.context.totalRecipes;
const selectedTagsSelector = (state: SearchMachineState) => state.context.selectedTags;

const View = () => {
  const tt = useSearchTranslations();
  const { service } = useContext(SearchPageStateContext);
  const isRecipesTab = useSelector(service, isRecipesTabSelector);
  const totalRecipes = useSelector(service, totalRecipesSelector);
  const selectedTags = useSelector(service, selectedTagsSelector);
  const showFilter = (totalRecipes !== 0 && isRecipesTab) || (isRecipesTab && selectedTags.length !== 0);

  return (
    <>
      <SEOReactHelmet title={tt.searchPage} />
      <QueryParamProvider>
        <div className="md:mx-auto" style={{ maxWidth: "1280px" }}>
          <section className="searchPanel p-5 xl:px-0 bg-white border-b-8 md:border-b border-solid border-lighter-grey">
            <SearchInput />
            <div className="grid grid-flow-col mt-5 w-full">
              <div className="justify-self-start">
                <Tabs />
              </div>
              <div className="justify-self-end">
                {showFilter && <FilterHeader className={"lg:block hidden"} />}
              </div>
            </div>
          </section>
          <section className="searchResult p-5 xl:px-0 bg-white">
            {showFilter && <FilterHeader className={"lg:hidden"} />}
            <SearchResult />
          </section>
        </div>
      </QueryParamProvider>
    </>
  );
};
