import { useActor, useSelector } from "@xstate/react";
import React, { useContext } from "react";
import useWindowWidth from "../../../hooks/useWindowWidth/useWindowWidth";
import { CloseIconBg } from "../../../icons/CloseIconBg";
import { MOBILE_LG } from "../../../utils/breakpoints";
import { SearchPageStateContext } from "../SearchPageStateProvider";
import { useSearchTranslations } from "../translations";
import { SearchMachineState } from "../types";
import FilterButton from "./FilterButton";

type Props = {
  className: string;
};

const totalRecipesSelector = (state: SearchMachineState) => state.context.totalRecipes;
const selectedTagsSelector = (state: SearchMachineState) => state.context.selectedTags;
const selectedCookTimeRangeSelector = (state: SearchMachineState) => state.context.selectedCookTimeLimit;
const selectedSatietyRangeSelector = (state: SearchMachineState) => state.context.selectedSatietyRange;
const filterViewSelector = (state: SearchMachineState) => state.matches("recipes.recipeView.filterView");

const recipesViewSelector = (state: SearchMachineState) => state.matches("recipes.recipeView.idle");

const FilterHeader = ({ className }: Props) => {
  const tt = useSearchTranslations();
  const windowWidth = useWindowWidth();
  const { service } = useContext(SearchPageStateContext);
  const [, send] = useActor(service);
  const totalRecipes = useSelector(service, totalRecipesSelector);
  const selectedTags = useSelector(service, selectedTagsSelector);
  const isFilterView = useSelector(service, filterViewSelector);
  const isRecipesView = useSelector(service, recipesViewSelector);
  const selectedCookTimeRange = useSelector(service, selectedCookTimeRangeSelector);
  const selectedSatietyRange = useSelector(service, selectedSatietyRangeSelector);

  const isFilterActive =
    selectedTags.length > 0 ||
    isFilterView ||
    selectedCookTimeRange.length > 0 ||
    selectedSatietyRange.length > 0;
  const isShowTotalRecipes = isRecipesView || (isFilterView && windowWidth > parseInt(MOBILE_LG));

  const modifiedSatietyRange = selectedSatietyRange.map((item) => {
    return { ...item, title: `${tt.filter.satietyScore} ${item.title}` };
  });

  return (
    <div className={`${className} mb-5 flex lg:mb-0`}>
      <div className="grid grid-flow-col gap-2">
        <div className="flex-none">
          <FilterButton isActive={isFilterActive} />
        </div>
        {(selectedTags.length > 0 || selectedSatietyRange.length > 0 || selectedCookTimeRange.length > 0) && (
          <div className="flex overflow-x-auto hideScrollbar items-center lg:max-w-lg">
            {selectedTags.map((tag, idx) => (
              <div
                className="border self-center h-10 min-w-max text-sm border-green p-3 rounded-3xl mx-1 leading-none cursor-pointer"
                key={idx}
                onClick={() => send({ type: "REMOVE_TAG", tag })}
              >
                {tag.title}
              </div>
            ))}

            {selectedCookTimeRange.map((tag, idx) => (
              <div
                className="border self-center h-10 min-w-max text-sm border-green p-3 rounded-3xl mx-1 leading-none cursor-pointer"
                key={idx}
                onClick={() => send({ type: "REMOVE_COOKTIME_RANGE_TAG", tag })}
              >
                {tag.title}
              </div>
            ))}

            {modifiedSatietyRange.map((tag, idx) => (
              <div
                className="border self-center h-10 min-w-max text-sm border-green p-3 rounded-3xl mx-1 leading-none cursor-pointer"
                key={idx}
                onClick={() => send({ type: "REMOVE_SATIETY_RANGE_TAG", tag })}
              >
                {tag.title}
              </div>
            ))}
          </div>
        )}

        {isShowTotalRecipes && (
          <div className="flex flex-col border-l border-lighter-grey pl-2 self-center justity-self-end">
            <span className="leading-none">{totalRecipes}</span>
            <span className="text-xs">{tt.recipes}</span>
          </div>
        )}

        {(selectedTags.length > 0 || selectedSatietyRange.length > 0 || selectedCookTimeRange.length > 0) && (
          <button className="p-0" onClick={() => send({ type: "CLEAR_SELECTED_TAGS" })}>
            <CloseIconBg className="bg-darker-grey rounded-3xl" />
          </button>
        )}
      </div>
    </div>
  );
};

export default FilterHeader;
