import { t } from "@lingui/macro";
import { useContext } from "react";
import { i18nContext } from "../I18nProvider/I18nProvider";

export function usePremiumRecipePopUpTranslations() {
  const { translations } = useContext(i18nContext);
  return {
    ...translations,
    title: t({
      id: "PremiumRecipePopUp.title",
      message: "Join and enjoy our premium recipes",
    }),
    description: t({
      id: "PremiumRecipePopUp.description",
      message:
        "Our selection of premium recipes is available exclusively to members. Get a taste with your free trial!",
    }),
    startTrialBtn: t({
      id: "PremiumRecipePopUp.startTrialBtn",
      message: "Start my free trial",
    }),
    learnMoreBtn: t({
      id: "PremiumRecipePopUp.learnMoreBtn",
      message: "Learn more",
    }),
    note: t({
      id: "PremiumRecipePopUp.note",
      message: "No commitment, cancel anytime. Already a member?",
    }),
    signInHere: t({
      id: "PremiumRecipePopUp.signInHere",
      message: "Sign in here",
    }),
  };
}
