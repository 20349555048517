import { useActor, useSelector } from "@xstate/react";
import React, { useContext } from "react";
import { LoadingIcon } from "../../../components/Icon/Icon";
import { getSrc } from "../../../utils/image";
import { SearchPageStateContext } from "../SearchPageStateProvider";
import { useSearchTranslations } from "../translations";
import { SearchMachineState } from "../types";
import ErrorMessage from "./ErrorMessage";

const getImageUrl = (data: any) => {
  //need to revisit this
  const imageSrc = new URL(data?.pagemap?.cse_image[0].src);
  const isWpImage = (imageSrc as URL).pathname?.includes("wp-content");
  return isWpImage ? getSrc((imageSrc as URL).pathname, { width: 400, height: 225 }) : imageSrc.toString();
};

const contextSelector = (state: SearchMachineState) => state.context;
const allViewSelector = (state: SearchMachineState) => state.matches("all.allView.idle");
const loadMoreDataSelector = (state: SearchMachineState) => state.matches("all.allView.loadMoreGoogleSearch");
const errorViewSelector = (state: SearchMachineState) => state.matches("all.allView.error");

const AllTab = () => {
  const tt = useSearchTranslations();
  const { service } = useContext(SearchPageStateContext);
  const [, send] = useActor(service);
  const isAllView = useSelector(service, allViewSelector);
  const isServerError = useSelector(service, errorViewSelector);
  const isLoadingData = useSelector(service, loadMoreDataSelector);
  const { allSearchData, showLoadMoreButtonAll } = useSelector(service, contextSelector);

  return (
    <>
      {allSearchData.length === 0 && isAllView && <ErrorMessage message={tt.searchedResultNotFound} />}
      {isServerError && <ErrorMessage message={tt.noConnectionError} />}
      <div className="grid grid-flow-row lg:grid-cols-3 md:grid-cols-2 gap-5 w-full">
        {allSearchData!.map((data, idx) => {
          if (data?.pagemap?.cse_image && data?.pagemap?.cse_image[0]?.src)
            return (
              <div className="flex pb-4 border-b md:pb-0 md:border-none border-lighter-grey" key={idx}>
                <a href={data.link} title={data.title} className="flex flex-col text-dark-grey">
                  <div className="relative overflow-hidden flex-none rounded-t-xl bg-bluegrey pt-16/9">
                    <img
                      className="w-full rounded-t-xl absolute top-0"
                      src={getImageUrl(data)}
                      alt={data.title}
                    />
                  </div>
                  <div className="flex-grow md:p-4 pt-4 md:border md:border-lighter-grey md:border-solid md:border-t-0 md:rounded-b-xl">
                    <h4 className="font-medium m-0 mb-2 text-overflow leading-6">{data.title}</h4>
                    <p className="m-0 text-base leading-5"> {data.snippet} </p>
                  </div>
                </a>
              </div>
            );
        })}
      </div>

      {showLoadMoreButtonAll && (
        <div className="flex justify-center w-full py-5 md:py-10">
          <button
            onClick={() => send({ type: "LOAD_MORE_RESULTS" })}
            className="p-3 w-40 bg-blue rounded-full text-white font-medium lg:hover:opacity-75 transition-all duration-500"
          >
            {isLoadingData ? <LoadingIcon /> : tt.loadMore}
          </button>
        </div>
      )}
    </>
  );
};

export default AllTab;
