import { useActor, useSelector } from "@xstate/react";
import React, { useContext } from "react";
import { StringParam, useQueryParam } from "use-query-params";
import { SearchPageStateContext } from "../SearchPageStateProvider";
import { useSearchTranslations } from "../translations";
import { SearchMachineState, TabItem } from "../types";

const tabs: TabItem[] = [
  { label: "Recipes", event: "SHOW_RECIPES_TAB", id: "recipes" },
  { label: "Meal plans", event: "SHOW_MEALPLANS_TAB", id: "mealplans" },
  { label: "All", event: "SHOW_ALL_TAB", id: "all" },
];

const activeTabSelector = (state: SearchMachineState) => state.context.activeTab;

export const Tabs = () => {
  const tt = useSearchTranslations();
  const { service } = useContext(SearchPageStateContext);
  const activeTab = useSelector(service, activeTabSelector);
  const [, send] = useActor(service);
  const [, setParam] = useQueryParam("t", StringParam);

  return (
    <ul className="flex flex-row list-none p-0 m-0 space-x-2">
      {tabs.map((tab, index) => (
        <li key={index}>
          <button
            onClick={() => {
              send({ type: tab.event, activeTab: tab.id });
              setParam(tab.id);
            }}
            className={
              "py-1.5 px-3.5 sm:px-5 min-w-min rounded-full " +
              (activeTab === tab.id ? "bg-darker-grey text-white" : "bg-bluegrey")
            }
          >
            {tt.tabLabel(tab)}
          </button>
        </li>
      ))}
    </ul>
  );
};
