import React, { useContext } from "react";
import { getSrc } from "../../../utils/image";
import { SearchPageStateContext } from "../SearchPageStateProvider";
import { useActor, useSelector } from "@xstate/react";
import { SearchMachineState } from "../types";
import { useSearchTranslations } from "../translations";
import CarbCircle from "./CarbsCircle";
import ErrorMessage from "./ErrorMessage";
import { MealPlanPage } from "../../mealplans/pages";
import { LoadingIcon } from "../../../components/Icon/Icon";

const contextSelector = (state: SearchMachineState) => state.context;
const mealplansViewSelector = (state: SearchMachineState) => state.matches("mealplans.mealplanView.idle");
const loadMoreMealplansSelector = (state: SearchMachineState) =>
  state.matches("mealplans.mealplanView.loadMoreMealplans");
const errorViewSelector = (state: SearchMachineState) => state.matches("mealplans.mealplanView.error");

const MealplansTab = () => {
  const tt = useSearchTranslations();
  const { service } = useContext(SearchPageStateContext);
  const [, send] = useActor(service);
  const isMealplansView = useSelector(service, mealplansViewSelector);
  const isLoadingMoreMealplans = useSelector(service, loadMoreMealplansSelector);
  const isServerError = useSelector(service, errorViewSelector);
  const { mealplans, showLoadMoreButtonMealplans, totalMealplans, link } = useSelector(
    service,
    contextSelector
  );

  const handleLoadMoreResult = () => send({ type: "LOAD_MORE_RESULTS" });

  return (
    <>
      {totalMealplans === 0 && isMealplansView && <ErrorMessage message={tt.searchedResultNotFound} />}
      {isServerError && <ErrorMessage message={tt.noConnectionError} />}
      <div className="grid grid-flow-row lg:grid-cols-4 md:grid-cols-3 gap-4 md:gap-5 w-full">
        {mealplans!.map((mealplan, idx) => (
          <div key={idx} className="flex pb-4 border-b md:pb-0 md:border-none border-lighter-grey w-full">
            <a
              href={link({ to: MealPlanPage, params: { slug: mealplan.slug } })}
              className="flex md:flex-col flex-row w-full text-dark-grey"
            >
              <div className="relative flex-none rounded-xl md:rounded-b-none w-3/8 md:w-full bg-bluegrey md:pt-full">
                <img
                  className="w-full rounded-xl md:rounded-b-none md:absolute md:top-0"
                  src={getSrc(mealplan.image?.hzImage?.path, {
                    width: 400,
                    height: 400,
                  })}
                  alt={mealplan.title}
                />
                <CarbCircle data={mealplan} />
              </div>
              <div className="px-4 py-3 flex-grow self-center md:self-stretch md:border md:border-lighter-grey md:border-solid md:border-t-0 md:rounded-b-xl">
                <span className="font-medium leading-5 inline-block">{mealplan.title}</span>
              </div>
            </a>
          </div>
        ))}
      </div>

      <div className="flex justify-center w-full py-5 md:py-10">
        {showLoadMoreButtonMealplans && (
          <button
            onClick={handleLoadMoreResult}
            className="p-3 w-40 bg-blue rounded-full text-white font-medium lg:hover:opacity-75 transition-all duration-500"
          >
            {isLoadingMoreMealplans ? <LoadingIcon /> : tt.loadMore}
          </button>
        )}
      </div>
    </>
  );
};

export default MealplansTab;
