import { useMachine } from "@xstate/react";
import React, { createContext, useContext, useMemo } from "react";
import { InterpreterFrom, StateMachine } from "xstate";
import { useLink } from "../../components/Link/Link";
import { pageContext } from "../../components/PageProvider/PageProvider";
import { GetPageContext, SearchPage as PageDefinition } from "../../pages";
import { machine } from "./machine";
import { SearchMachineContext, SearchMachineEvents } from "./types";

type SearchPageContext = GetPageContext<typeof PageDefinition>;
type SearchInterpreter = InterpreterFrom<StateMachine<SearchMachineContext, any, SearchMachineEvents>>;
export const SearchPageStateContext = createContext<{
  service: InterpreterFrom<StateMachine<SearchMachineContext, any, SearchMachineEvents>>;
}>({ service: undefined as unknown as SearchInterpreter });

const availableTabs = ["recipes", "mealplans", "all"];

// check if tab if available other set default to recipes
const checkIfTabAvailable = (tab = "") => {
  return availableTabs.includes(tab) ? tab : "recipes";
};

export const SearchPageStateProvider: React.FunctionComponent<{ children?: React.ReactNode }> = (props) => {
  const { query } = useContext<SearchPageContext>(pageContext);
  const { s, t } = query ?? { s: "", t: "" };
  const link = useLink();
  const { locale } = useContext(pageContext);

  const m = useMemo(
    () =>
      machine({
        searchQuery: s || "",
        tab: checkIfTabAvailable(t),
        link,
        locale,
      }),
    [link, locale, s, t]
  );
  const [, , service] = useMachine(m);

  return (
    <SearchPageStateContext.Provider value={{ service }}>{props.children}</SearchPageStateContext.Provider>
  );
};
