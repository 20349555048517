import { useActor } from "@xstate/react";
import React, { useContext } from "react";
import { FilterIcon } from "../../../icons/FilterIcon";
import { SearchPageStateContext } from "../SearchPageStateProvider";

type Props = {
  isActive: boolean;
};

const FilterButton = ({ isActive }: Props) => {
  const { service } = useContext(SearchPageStateContext);
  const [, send] = useActor(service);

  const toggleFilterView = () => send({ type: "TOGGLE_FILTER" });

  const activeClass = isActive ? "border-green" : "bg-bluegrey border-bluegrey";

  return (
    <button onClick={toggleFilterView} className={`${activeClass} border p-1 rounded-full w-10 h-10`}>
      <div className="flex justify-center">
        <FilterIcon />
      </div>
    </button>
  );
};

export default FilterButton;
