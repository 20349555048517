import { useActor, useSelector } from "@xstate/react";
import Cookies from "js-cookie";
import React, { MouseEvent, useCallback, useContext, useEffect } from "react";
import { StringParam, useQueryParam } from "use-query-params";
import { LoadingIcon } from "../../../components/Icon/Icon";
import { PremiumRecipePopUp } from "../../../components/PremiumRecipePopUp/PremiumRecipePopUp";
import { useUser } from "../../../hooks/useUser/useUser";
import { SearchRecipesQuery_listRecipes_recipes } from "../../../types/Search_GetRecipes";
import { getRecipeTime } from "../../../utils/recipe";
import { SearchPageStateContext } from "../SearchPageStateProvider";
import { useSearchTranslations } from "../translations";
import { SearchMachineState } from "../types";
import ErrorMessage from "./ErrorMessage";
import FilterView from "./FilterView";
import { RecipeResult } from "./RecipeResult";

const totalRecipesSelector = (state: SearchMachineState) => state.context.totalRecipes;
const recipesSelector = (state: SearchMachineState) => state.context.recipes;
const showLoadMoreButtonRecipesSelector = (state: SearchMachineState) =>
  state.context.showLoadMoreButtonRecipes;
const selectedTagsSelector = (state: SearchMachineState) => state.context.selectedTags;
const shouldRedirectSelector = (state: SearchMachineState) => state.context.shouldRedirect;
const linkSelector = (state: SearchMachineState) => state.context.link;

const recipeViewSelector = (state: SearchMachineState) => state.matches("recipes.recipeView.idle");
const recipeViewLockSelector = (state: SearchMachineState) => state.matches("recipes.recipeView.locked");
const loadMoreRecipesSelector = (state: SearchMachineState) =>
  state.matches("recipes.recipeView.loadMoreRecipes");
const filterViewSelector = (state: SearchMachineState) => state.matches("recipes.recipeView.filterView");
const errorViewSelector = (state: SearchMachineState) => state.matches("recipes.recipeView.error");

const RecipesTab = () => {
  const tt = useSearchTranslations();
  const [, setParam] = useQueryParam("t", StringParam);
  const { service } = useContext(SearchPageStateContext);
  const [, send] = useActor(service);

  const recipes = useSelector(service, recipesSelector);
  const totalRecipes = useSelector(service, totalRecipesSelector);
  const selectedTags = useSelector(service, selectedTagsSelector);
  const shouldRedirect = useSelector(service, shouldRedirectSelector);
  const link = useSelector(service, linkSelector);
  const showLoadMoreButtonRecipes = useSelector(service, showLoadMoreButtonRecipesSelector);

  const isRecipeLockView = useSelector(service, recipeViewLockSelector);
  const isRecipeView = useSelector(service, recipeViewSelector);
  const isLoadingMoreRecipes = useSelector(service, loadMoreRecipesSelector);
  const isFilterView = useSelector(service, filterViewSelector);
  const isServerError = useSelector(service, errorViewSelector);
  const { loggedIn, isPremium } = useUser();

  const handleLoadMoreResult = () => send({ type: "LOAD_MORE_RESULTS" });

  const isAllowedToSeeRecipe = useCallback(
    (recipe: any) => {
      // in any of these case the recipe can be seen for free
      if (isPremium || !recipe.isMembersOnly) return true;

      // if the user is not premium, it is allowed
      // to see 3 premium recipes for free, after that the recipe is locked
      const lockedRecipesViewedCookie = Cookies.get("ckdc-premium") ?? "";
      const lockedRecipesViewed = lockedRecipesViewedCookie.split(",").length;
      return lockedRecipesViewed < 3;
    },
    [isPremium]
  );

  const redirectToAll = useCallback(() => {
    send({ type: "SHOW_ALL_TAB", activeTab: "all" });
    setParam("all");
  }, [setParam, send]);

  const closeLockPopUp = useCallback(() => {
    send({ type: "CLOSE" });
  }, [send]);

  const onClickOnRecipe = useCallback(
    (recipe: SearchRecipesQuery_listRecipes_recipes) => (event: MouseEvent) => {
      if (!isAllowedToSeeRecipe(recipe)) {
        event.preventDefault();
        send({ type: "LOCK" });
      }
    },
    [isAllowedToSeeRecipe, send]
  );

  useEffect(() => {
    if (totalRecipes === 0 && isRecipeView && selectedTags.length === 0 && shouldRedirect) {
      redirectToAll();
    }
  }, [totalRecipes, isRecipeView, selectedTags, redirectToAll, shouldRedirect]);

  return (
    <>
      {totalRecipes === 0 && isRecipeView && <ErrorMessage message={tt.searchedResultNotFound} />}
      {isServerError && <ErrorMessage message={tt.noConnectionError} />}
      {isFilterView && <FilterView />}

      {(isRecipeView || isLoadingMoreRecipes || isRecipeLockView) && (
        <>
          {isRecipeLockView && <PremiumRecipePopUp onClose={closeLockPopUp} />}
          <div className="grid grid-flow-row lg:grid-cols-4 md:grid-cols-3 gap-4 md:gap-5 w-full">
            {recipes!.map((recipe) => (
              <RecipeResult
                key={recipe.id}
                onClickOnRecipe={onClickOnRecipe(recipe)}
                recipe={recipe}
                link={link}
                loggedIn={loggedIn}
                isPremium={isPremium}
                isAllowedToSeeRecipe={isAllowedToSeeRecipe}
                time={tt.quantities.timeInMinutes(getRecipeTime(recipe.time))}
              />
            ))}
          </div>
          <div className="flex justify-center w-full py-5 md:py-10">
            {showLoadMoreButtonRecipes && (
              <button
                onClick={handleLoadMoreResult}
                className="p-3 w-40 bg-blue rounded-full text-white font-medium lg:hover:opacity-75 transition-all duration-500"
              >
                {isLoadingMoreRecipes ? <LoadingIcon /> : tt.loadMore}
              </button>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default RecipesTab;
