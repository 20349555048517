import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { createExternalPage } from "../../page-utils";
import { localePrefix, LoginPage } from "../../pages";
import LockIcon from "../../icons/LockIcon";
import { Locale } from "../../types/Locale";
import { Link } from "../Link/Link";
import { usePremiumRecipePopUpTranslations } from "./translations";

export function PremiumRecipePopUp({ onClose }: { onClose: VoidFunction }) {
  const translations = usePremiumRecipePopUpTranslations();
  const images = useStaticQuery(graphql`
    query {
      premiumLockDesktop: file(relativePath: { eq: "premium-lock-desktop.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 450)
        }
      }
      premiumLockMobile: file(relativePath: { eq: "premium-lock-mobile.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 450)
        }
      }
    }
  `);

  return (
    <div className="fixed top-0 left-0 z-50 flex justify-center items-center bg-black h-full w-full bg-opacity-90">
      <div className="relative w-[359px] md:w-[709px] top-6 md:top-auto bg-white rounded-lg shadow-md">
        <div className="z-10 absolute flex justify-center items-center left-1/2 -translate-x-1/2 -translate-y-1/2 w-16 h-16 bg-white rounded-full">
          <LockIcon className="relative w-16 h-16 md:w-20 md:h-20" />
        </div>
        <div
          onClick={onClose}
          className="z-10 absolute right-3 top-3 text-[#163C4E] cursor-pointer hover:bg-gray-blue-500 transition-all rounded-full p-3"
        >
          <svg
            className="block"
            width="26"
            height="26"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <line x1="18" y1="6" x2="6" y2="18" />
            <line x1="6" y1="6" x2="18" y2="18" />
          </svg>
        </div>
        <div className="flex flex-col md:flex-row p-8 h-full">
          <div className="flex-grow flex-shrink-0 -mx-8 -mt-8 md:w-1/3 md:-mx-0 md:-ml-8 md:-mb-8 rounded-lg overflow-hidden">
            <GatsbyImage
              image={images.premiumLockDesktop?.childImageSharp?.gatsbyImageData}
              className="!hidden md:!block w-100"
              alt=""
            />
            <GatsbyImage
              image={images.premiumLockMobile?.childImageSharp?.gatsbyImageData}
              className="md:!hidden w-100"
              alt=""
            />
          </div>
          <div className="md:px-8">
            <h1 className="m-0 text-3xl tracking-tight md:text-4xl text-[#37664E] font-medium">
              {translations.title}
            </h1>
            <p className="text-[0.95rem] mb-6 mt-4 text-[#3C3C3C]">{translations.description}</p>
            <div className="flex -mx-3 gap-3 text-sm">
              <Link
                className="block rounded-full flex-grow md:flex-grow-0 py-4 px-6 text-center font-medium text-white bg-[#06B05B] border border-[#06B05B] hover:bg-white hover:text-[#06B05B] transition-all"
                to={StartFreeTrialPage}
              >
                {translations.startTrialBtn}
              </Link>
              <Link
                className="block rounded-full flex-grow md:flex-grow-0 py-4 px-6 text-center font-medium text-[#06B05B] bg-white border border-[#06B05B] hover:bg-[#06B05B] hover:text-white transition-all"
                to={LearnMorePage}
              >
                {translations.learnMoreBtn}
              </Link>
            </div>
            <p className="mb-0 mt-6 text-[0.63rem] text-[#1A1A1A] tracking-tight">
              {translations.note}{" "}
              <Link
                className="underline hover:opacity-60"
                to={LoginPage}
                query={{ redirectTo: document.location.href }}
              >
                {translations.signInHere}
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

const StartFreeTrialPage = createExternalPage({
  name: "start_free_trial",
  path: (locale) => {
    switch (locale) {
      case Locale.ES:
        return `${localePrefix[locale]}/hazte-miembro/account?content=recipe&source=feature-lock&_r=1`;
      case Locale.SV:
        return `${localePrefix[locale]}/bli-medlem/account?content=recipe&source=feature-lock&_r=1`;
      default:
        return `${localePrefix[locale]}/become-member/account?content=recipe&source=feature-lock&_r=1`;
    }
  },
});

const LearnMorePage = createExternalPage({
  name: "learn_more",
  path: (locale) => {
    switch (locale) {
      case Locale.ES:
        return `${localePrefix[locale]}/nuevo-miembro/recetas&source=feature-lock`;
      case Locale.SV:
        return `${localePrefix[locale]}/ny-medlem&source=feature-lock`;
      default:
        return `${localePrefix[locale]}/new-member/recipes&source=feature-lock`;
    }
  },
});
