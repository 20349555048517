import React, { useCallback, useContext, useEffect, useRef } from "react";
import { CloseIcon } from "../../../icons/CloseIcon";
import { SearchIcon } from "../../../icons/SearchIcon";
import { useQueryParam, StringParam } from "use-query-params";
import { useState } from "react";
import { SearchPageStateContext } from "../SearchPageStateProvider";
import { useActor, useSelector } from "@xstate/react";
import { SearchMachineState } from "../types";

type ChangeEvent = React.ChangeEvent<HTMLInputElement>;
type KeydownEvent = React.KeyboardEvent<HTMLInputElement>;

const queryParamSelector = (state: SearchMachineState) => state.context.searchQuery;

const SearchInput = () => {
  const { service } = useContext(SearchPageStateContext);
  const queryParam = useSelector(service, queryParamSelector);
  const [, send] = useActor(service);

  const [, setParam] = useQueryParam("s", StringParam);
  const clearRef = useRef<HTMLButtonElement>(null);
  const serchRef = useRef<HTMLButtonElement>(null);

  const [searchQuery, setSearchQuery] = useState(queryParam ?? "");

  const hasQuery = searchQuery?.trim() !== "";

  const handleOnchange = (e: ChangeEvent): void => {
    const query = e.target.value;
    setSearchQuery(query);
    udpateSearchQuery(query);
  };

  const udpateSearchQuery = useCallback(
    (query: string) => {
      send({ type: "SEARCH_QUERY_CHANGE", value: query });
    },
    [send]
  );

  // clears the input text
  const handleClear = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      send({ type: "CLEAR_SEARCH_INPUT" });
      setParam("");
      setSearchQuery("");
    },
    [setParam, setSearchQuery, send]
  );

  const handleSearch = useCallback(() => {
    setParam(searchQuery);
    send({ type: "SEARCH_RESULT" });
  }, [setParam, searchQuery, send]);

  // when the user press "Enter" it performs a search
  const onKeyDown = (e: KeydownEvent) => {
    if (e.key === "Enter") {
      setParam(searchQuery);
      send({ type: "SEARCH_RESULT" });
    }
  };

  const hasQueryClass = hasQuery ? "opacity-100" : "opacity-0";

  useEffect(() => {
    const el = clearRef.current;
    el?.addEventListener("click", handleClear);
    return () => el?.removeEventListener("click", handleClear);
  }, [handleClear]);

  useEffect(() => {
    const el = serchRef.current;
    el?.addEventListener("click", handleSearch);
    return () => el?.removeEventListener("click", handleSearch);
  }, [handleSearch]);

  return (
    <div className="max-w-md mx-auto">
      <div className="lg:focus:outline-none flex items-center w-full border border-light-grey border-solid rounded-full p-1.5">
        <button
          ref={serchRef}
          tabIndex={0}
          className="pointer-events-auto p-0 h-9 w-9 grow-0 border-0 bg-transparent flex justify-center items-center cursor-pointer lg:hover:bg-lighter-grey rounded-full transition-opacity duration-300 delay-150"
        >
          <SearchIcon className="pointer-events-none text-grey" />
        </button>

        <div className="flex-grow h-full">
          <input
            tabIndex={0}
            className="w-full border-transparent leading-6 text-base outline-none text-dark-grey"
            type="text"
            value={searchQuery}
            spellCheck="false"
            maxLength={150}
            autoCapitalize="off"
            autoComplete="off"
            autoCorrect="off"
            onChange={handleOnchange}
            onKeyDown={onKeyDown}
          />
        </div>
        <button
          tabIndex={0}
          ref={clearRef}
          className={`lg:focus:outline-none pointer-events-auto p-0 h-9 w-9 grow-0 border-0 bg-transparent flex justify-center items-center cursor-pointer lg:hover:bg-lighter-grey rounded-full transition-opacity duration-300 delay-150 ${hasQueryClass}`}
        >
          <CloseIcon className="pointer-events-none text-grey" />
        </button>
      </div>
    </div>
  );
};

export default SearchInput;
