import * as React from "react";
import { IconProps } from "./types";

export const SearchIcon = ({ className }: IconProps) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24">
    <path
      d="M21.2897 19.9072L16.5246 15.1462C17.6138 13.8766 18.2491 12.1989 18.2491 10.4306C18.2491 6.34971 14.9362 3.08502 10.8973 3.08502C6.85826 3.08502 3.5 6.34971 3.5 10.3852C3.5 14.4207 6.81288 17.7308 10.8519 17.7308C12.6218 17.7308 14.2555 17.096 15.5262 16.0531L20.3367 20.8594C20.4728 20.9955 20.6544 21.0408 20.8359 21.0408C21.0174 21.0408 21.199 20.9955 21.3351 20.8594C21.562 20.5874 21.562 20.1793 21.2897 19.9072ZM10.8973 16.4158C7.58438 16.4158 4.90684 13.7406 4.90684 10.4306C4.90684 7.12054 7.53899 4.39997 10.8973 4.39997C14.2555 4.39997 16.8877 7.0752 16.8877 10.3852C16.8877 13.6953 14.2101 16.4158 10.8973 16.4158Z"
      fill="currentColor"
    />
  </svg>
);
