import { useActor, useSelector } from "@xstate/react";
import React, { useContext } from "react";
import { SearchPageStateContext } from "../SearchPageStateProvider";
import { useSearchTranslations } from "../translations";
import { GetSearchTags_listTags_tags, SearchMachineState } from "../types";
import { upperFirst } from "../../../utils/string";
import { useMealPlannerTranslations } from "../../mealplans/mealplan/components/MealPlanner/translations";
import { useTags } from "../../../utils/tags";
import { Link } from "../../../components/Link/Link";
import { SatietyScorePage } from "../../../pages";
import { satietyScoreRange } from "../../../constants/satietyScoreRange";
import { cookingTimeRange } from "../../../constants/cookingTimeRange";
import { InfoIcon } from "../../../icons/InfoIcon";
import { getSelectedCookingTimeRange, getSelectedSatietyScoreRange } from "../../../utils/utils";
import { RangeType } from "../../../constants/types";

const allTagsSelector = (state: SearchMachineState) => state.context.allTags;
const selectedTagsSelector = (state: SearchMachineState) => state.context.selectedTags;
const selectedSatietyRangeSelector = (state: SearchMachineState) => state.context.selectedSatietyRange;
const selectedCookTimeRangeSelector = (state: SearchMachineState) => state.context.selectedCookTimeLimit;

const FilterView = () => {
  const ttSearchTranslations = useSearchTranslations();
  const ttMealPlannerTranslations = useMealPlannerTranslations();
  const tt = { ...ttSearchTranslations, ...ttMealPlannerTranslations };

  const { service } = useContext(SearchPageStateContext);
  const [, send] = useActor(service);
  const allTags = useSelector(service, allTagsSelector);
  const selectedTags = useSelector(service, selectedTagsSelector);

  const selectedCookTimeRange = useSelector(service, selectedCookTimeRangeSelector);
  const selectedSatietyRange = useSelector(service, selectedSatietyRangeSelector);

  const { tags } = useTags(allTags, tt);

  const isActiveTagStyle = (tagId: string) => {
    const stdStyle = "py-1.5 px-4 text-base min-w-min rounded-full mr-2 mb-2";
    const tagStyle = selectedTags.some((selectedTag) => selectedTag.id === tagId)
      ? `border border-green ${stdStyle}`
      : ` border border-bluegrey bg-bluegrey ${stdStyle}`;

    return tagStyle;
  };

  const handleClickOnTag = (tag: GetSearchTags_listTags_tags) => {
    selectedTags.some((selectedTag) => selectedTag.id === tag.id)
      ? send({ type: "REMOVE_TAG", tag })
      : send({ type: "ADD_TAG", tag });
  };

  const setCookTimeLimit = (cookTimeLimit: RangeType) => {
    const selectedCookTime = getSelectedCookingTimeRange(selectedCookTimeRange, cookTimeLimit);

    send({
      type: "SET_COOK_TIME_LIMIT",
      selectedCookTimeRange: selectedCookTime,
    });
  };

  const setSatietyScoreRange = (satietyScore: RangeType) => {
    const satietyScoreValues = getSelectedSatietyScoreRange(selectedSatietyRange, satietyScore);

    send({
      type: "SET_SATIETY_SCORE_RANGE",
      selectedSatietyRange: satietyScoreValues,
    });
  };

  return (
    <div className="flex flex-col relative">
      <div className="mb-4">
        <h3 className="font-medium text-xl m-0 mb-2"> {tt.filter.cookingTime} </h3>
        <div className="flex flex-wrap">
          {cookingTimeRange.map((range) => (
            <button
              key={range.id}
              onClick={() => setCookTimeLimit(range)}
              className={`${
                selectedCookTimeRange.some((selected) => selected.id === range.id)
                  ? "border border-green"
                  : "border border-bluegrey bg-bluegrey"
              } py-1.5 px-4 text-base min-w-min rounded-full mr-2 mb-2`}
            >
              {upperFirst(range.title)}
            </button>
          ))}
        </div>
      </div>
      <div className="mb-4">
        <div className="flex flex-row items-center">
          <h3 className="font-medium text-xl m-0 mb-2"> {tt.filter.satietyScore} </h3>
          <Link to={SatietyScorePage} target="_blank" className="ml-1">
            <InfoIcon className="text-green" />
          </Link>
        </div>

        <div className="flex flex-wrap">
          {satietyScoreRange.map((range) => (
            <button
              key={range.id}
              onClick={() => setSatietyScoreRange(range)}
              className={`${
                selectedSatietyRange.some((selected) => selected.id === range.id)
                  ? "border border-green"
                  : "border border-bluegrey bg-bluegrey"
              } py-1.5 px-4 text-base min-w-min rounded-full mr-2 mb-2`}
            >
              {upperFirst(range.title)}
            </button>
          ))}
        </div>
      </div>
      {tags.map((tags) => (
        <div key={tags.title} className="mb-4">
          <h3 className="font-medium text-xl m-0 mb-2">{tags.title}</h3>
          <div className="flex flex-wrap">
            {tags.tags.map((tag) => (
              <button onClick={() => handleClickOnTag(tag)} className={isActiveTagStyle(tag.id)} key={tag.id}>
                {upperFirst(tag.title)}
              </button>
            ))}
          </div>
        </div>
      ))}
      <div className="flex justify-center sticky bottom-8 md:bottom-0 items-center md:bg-white md:py-5">
        <button
          onClick={() => {
            send({ type: "SEARCH_RESULT" });
          }}
          className="rounded-full px-10 py-3.5 text-base font-medium bg-green text-white hover:bg-light-green shadow-2xl"
        >
          {tt.showResults}
        </button>
      </div>
    </div>
  );
};

export default FilterView;
