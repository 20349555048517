import { assign, createMachine, DoneInvokeEvent, MachineConfig } from "xstate";
import {
  AddTagEvent,
  ChangeCookTimeLimitEvent,
  GetSearchTags_listTags_tags,
  MachineOptions,
  RemoveCooktimeRangeTagEvent,
  RemoveSatietyRangeTagEvent,
  RemoveTagEvent,
  SearchMachineContext,
  SearchMachineEvents,
  SearchQueryChangeEvent,
  SetSatietyScoreRangeEvent,
  ShowTabEvent,
} from "./types";
import { client } from "../../utils/apollo-client";
import GetRecipesSearch from "./api/GetRecipesSearch.graphql";
import GetMealPlansSearch from "./api/GetMealPlansSearch.graphql";
import GetSearchTagsQuery from "./api/GetSearchTags.graphql";
import {
  SearchRecipesQuery_listRecipes,
  SearchRecipesQuery_listRecipes_recipes,
} from "../../types/Search_GetRecipes";
import {
  SearchMealPlansQuery_listMealPlans_mealPlans,
  SearchRecipesQuery_listMealPlans,
} from "../../types/Search_GetMealPlans";
import { LinkFn } from "../../components/Link/Link";
import { SearchGoogleDataItems, SearchGoogleData } from "../../types/Search_GetGoogleSearch";
import { getEngineId } from "./utils";
import { getMinMaxValue } from "../../utils/utils";

const machineConfig = (
  searchQuery: string,
  link: LinkFn,
  locale: string,
  tab: string
): MachineConfig<SearchMachineContext, any, SearchMachineEvents> => {
  return {
    id: "search",
    initial: tab,
    context: {
      recipes: [],
      mealplans: [],
      allSearchData: [],
      allTags: [],
      selectedTags: [],
      filterInput: [],
      selectedCookTimeLimit: [],
      selectedSatietyRange: [],
      link,
      locale,
      searchQuery,
      activeTab: tab,
      totalRecipes: 0,
      totalMealplans: 0,
      pageNumberRecipesSearch: 1,
      pageNumberMealplanSearch: 1,
      pageNumberGoogleSearch: 0,
      showLoadMoreButtonRecipes: false,
      showLoadMoreButtonMealplans: false,
      showLoadMoreButtonAll: false,
      shouldRedirect: true,
    },
    on: {
      SEARCH_QUERY_CHANGE: {
        actions: ["searchQueryChange"],
      },
    },
    states: {
      recipes: {
        id: "recipes",
        entry: "resetPageInputForSearchRecipes",
        initial: "loadSearchRecipes",
        on: {
          SHOW_MEALPLANS_TAB: {
            target: "#search.mealplans",
            actions: "setActiveTab",
          },
          SHOW_ALL_TAB: {
            target: "#search.all",
            actions: "setActiveTab",
          },
        },
        states: {
          loadSearchRecipes: {
            invoke: {
              src: "searchRecipes",
              onDone: {
                actions: ["setSearchedRecipes"],
                target: "recipeView",
              },
              onError: {
                actions: "logError",
                target: "#recipeView.error",
              },
            },
          },
          recipeView: {
            id: "recipeView",
            initial: "init",
            states: {
              init: {
                always: [
                  {
                    cond: "hasTagsLoaded",
                    target: "loadTags",
                  },
                  { target: "idle" },
                ],
              },
              locked: {
                on: {
                  CLOSE: "idle",
                },
              },
              idle: {
                on: {
                  LOCK: "locked",
                  TOGGLE_FILTER: {
                    target: "#recipeView.filterView",
                  },
                  SEARCH_RESULT: {
                    actions: ["resetPageInputForSearchRecipes"],
                    target: "#recipes.loadSearchRecipes",
                  },
                  LOAD_MORE_RESULTS: {
                    actions: "setPageInputForSearchRecipes",
                    target: "loadMoreRecipes",
                  },
                  REMOVE_TAG: {
                    actions: ["removeTag"],
                    target: "#recipes.loadSearchRecipes",
                  },
                  REMOVE_COOKTIME_RANGE_TAG: {
                    actions: ["removeCooktimeRangeTag"],
                    target: "#recipes.loadSearchRecipes",
                  },
                  REMOVE_SATIETY_RANGE_TAG: {
                    actions: ["removeSatietyRangeTag"],
                    target: "#recipes.loadSearchRecipes",
                  },
                },
              },
              loadMoreRecipes: {
                invoke: {
                  src: "searchRecipes",
                  onDone: {
                    actions: ["setSearchedRecipesOnLoadMore"],
                    target: "#recipes.recipeView.idle",
                  },
                  onError: {
                    actions: "logError",
                    target: "error",
                  },
                },
              },
              loadTags: {
                invoke: {
                  src: "getAllTags",
                  onDone: {
                    actions: ["setAllTags"],
                    target: "#recipeView.idle",
                  },
                  onError: {
                    actions: "logError",
                    target: "error",
                  },
                },
              },
              filterView: {
                on: {
                  TOGGLE_FILTER: {
                    target: "#recipeView.idle",
                  },
                  SEARCH_RESULT: {
                    actions: ["resetPageInputForSearchRecipes"],
                    target: "#recipes.loadSearchRecipes",
                  },
                  ADD_TAG: {
                    actions: ["addTag"],
                  },
                  SET_COOK_TIME_LIMIT: {
                    actions: ["setCookTimeLimit"],
                  },
                  SET_SATIETY_SCORE_RANGE: {
                    actions: ["setSatietyScoreRange"],
                  },
                  REMOVE_TAG: {
                    actions: ["removeTag"],
                  },
                  REMOVE_COOKTIME_RANGE_TAG: {
                    actions: ["removeCooktimeRangeTag"],
                  },
                  REMOVE_SATIETY_RANGE_TAG: {
                    actions: ["removeSatietyRangeTag"],
                  },
                },
              },
              error: {},
            },
            on: {
              CLEAR_SELECTED_TAGS: {
                actions: ["clearSelectedTags", "resetPageInputForSearchRecipes"],
                target: "loadSearchRecipes",
              },
              SEARCH_RESULT: {
                actions: ["resetPageInputForSearchRecipes"],
                target: "loadSearchRecipes",
              },
              CLEAR_SEARCH_INPUT: {
                actions: "clearSearchInput",
                target: "loadSearchRecipes",
              },
            },
          },
        },
      },
      mealplans: {
        id: "mealplans",
        initial: "loadMealPlan",
        entry: "resetPageInputForSearchMealplans",
        on: {
          SHOW_RECIPES_TAB: {
            target: "#search.recipes",
            actions: "setActiveTab",
          },
          SHOW_ALL_TAB: {
            target: "#search.all",
            actions: "setActiveTab",
          },
        },
        states: {
          loadMealPlan: {
            invoke: {
              src: "searchMealPlans",
              onDone: {
                actions: ["setMealPlans"],
                target: "mealplanView",
              },
              onError: {
                actions: "logError",
                target: "#mealplanView.error",
              },
            },
          },
          mealplanView: {
            id: "mealplanView",
            initial: "idle",
            states: {
              idle: {
                on: {
                  LOAD_MORE_RESULTS: {
                    actions: ["setPageInputForSearchMealplans"],
                    target: ["loadMoreMealplans"],
                  },
                },
              },
              loadMoreMealplans: {
                invoke: {
                  src: "searchMealPlans",
                  onDone: {
                    actions: ["setSearchedMealPlansOnLoadMore"],
                    target: "#mealplans.mealplanView",
                  },
                  onError: {
                    actions: "logError",
                    target: "error",
                  },
                },
              },
              error: {},
            },
            on: {
              SEARCH_RESULT: {
                actions: ["resetPageInputForSearchMealplans"],
                target: "loadMealPlan",
              },
              CLEAR_SEARCH_INPUT: {
                actions: "clearSearchInput",
                target: "loadMealPlan",
              },
            },
          },
        },
      },
      all: {
        id: "all",
        initial: "loadGoogleSearch",
        on: {
          SHOW_RECIPES_TAB: {
            target: "#search.recipes",
            actions: "setActiveTab",
          },
          SHOW_MEALPLANS_TAB: {
            target: "#search.mealplans",
            actions: "setActiveTab",
          },
        },
        states: {
          loadGoogleSearch: {
            invoke: {
              src: "searchGoogle",
              onDone: {
                actions: ["setAllGoogleSearch"],
                target: "allView",
              },
              onError: {
                actions: "logError",
                target: "#allView.error",
              },
            },
          },
          allView: {
            id: "allView",
            initial: "idle",
            states: {
              idle: {
                on: {
                  LOAD_MORE_RESULTS: {
                    actions: ["setPageInputForGoogleSearch"],
                    target: ["loadMoreGoogleSearch"],
                  },
                },
              },
              loadMoreGoogleSearch: {
                invoke: {
                  src: "searchGoogle",
                  onDone: {
                    actions: ["setAllGoogleSearchOnLoadMore"],
                    target: "#all.allView",
                  },
                  onError: {
                    actions: "logError",
                    target: "error",
                  },
                },
              },
              error: {},
            },
            on: {
              SEARCH_RESULT: {
                actions: ["resetPageInputForGoogleSearch"],
                target: "loadGoogleSearch",
              },
              CLEAR_SEARCH_INPUT: {
                actions: "clearSearchInput",
                target: "loadGoogleSearch",
              },
            },
          },
        },
      },
    },
  };
};

export const machine = ({ searchQuery, link, locale, tab }: MachineOptions) => {
  return createMachine<SearchMachineContext, SearchMachineEvents>(
    machineConfig(searchQuery, link, locale, tab),
    {
      guards: {
        hasTagsLoaded: (ctx) => ctx.allTags.length === 0,
      },
      actions: {
        searchQueryChange: assign({
          searchQuery: (_, event) => (event as SearchQueryChangeEvent).value,
        }),
        setActiveTab: assign({
          shouldRedirect: (_, event) => false, // eslint-disable-line @typescript-eslint/no-unused-vars
          activeTab: (_, event) => (event as ShowTabEvent).activeTab,
        }),
        clearSearchInput: assign({
          searchQuery: (_, event) => "", // eslint-disable-line @typescript-eslint/no-unused-vars
          recipes: (_, event) => [], // eslint-disable-line @typescript-eslint/no-unused-vars
          mealplans: (_, event) => [], // eslint-disable-line @typescript-eslint/no-unused-vars
        }),

        logError: (_, event) => {
          console.error((event as DoneInvokeEvent<any>).data);
        },

        setSearchedRecipes: assign({
          showLoadMoreButtonRecipes: (_, event) =>
            (event as DoneInvokeEvent<SearchRecipesQuery_listRecipes>).data.nextPage !== null,
          recipes: (_, event) => {
            return (event as DoneInvokeEvent<SearchRecipesQuery_listRecipes>).data
              .recipes as SearchRecipesQuery_listRecipes_recipes[];
          },
          totalRecipes: (_, event) => (event as DoneInvokeEvent<SearchRecipesQuery_listRecipes>).data.totalSize,
        }),
        setSearchedRecipesOnLoadMore: assign({
          showLoadMoreButtonRecipes: (_, event) =>
            (event as DoneInvokeEvent<SearchRecipesQuery_listRecipes>).data.nextPage !== null,
          recipes: (ctx, event) => {
            return [
              ...ctx.recipes!,
              ...((event as DoneInvokeEvent<SearchRecipesQuery_listRecipes>).data
                .recipes as SearchRecipesQuery_listRecipes_recipes[]),
            ];
          },
        }),
        setPageInputForSearchRecipes: assign({
          pageNumberRecipesSearch: (ctx) => ctx.pageNumberRecipesSearch + 1,
        }),
        resetPageInputForSearchRecipes: assign({
          pageNumberRecipesSearch: (_, event) => 1, // eslint-disable-line @typescript-eslint/no-unused-vars
        }),

        setMealPlans: assign({
          showLoadMoreButtonMealplans: (_, event) =>
            (event as DoneInvokeEvent<SearchRecipesQuery_listMealPlans>).data.nextPage !== null,
          mealplans: (_, event) => {
            return (event as DoneInvokeEvent<SearchRecipesQuery_listMealPlans>).data
              .mealplans as SearchMealPlansQuery_listMealPlans_mealPlans[];
          },
          totalMealplans: (_, event) =>
            (event as DoneInvokeEvent<SearchRecipesQuery_listRecipes>).data.totalSize,
        }),
        setSearchedMealPlansOnLoadMore: assign({
          showLoadMoreButtonMealplans: (_, event) =>
            (event as DoneInvokeEvent<SearchRecipesQuery_listMealPlans>).data.nextPage !== null,
          mealplans: (ctx, event) => {
            return [
              ...ctx.mealplans!,
              ...((event as DoneInvokeEvent<SearchRecipesQuery_listMealPlans>).data
                .mealplans as SearchMealPlansQuery_listMealPlans_mealPlans[]),
            ];
          },
        }),
        setPageInputForSearchMealplans: assign({
          pageNumberMealplanSearch: (ctx) => ctx.pageNumberMealplanSearch + 1,
        }),
        resetPageInputForSearchMealplans: assign({
          pageNumberMealplanSearch: (_, event) => 1, // eslint-disable-line @typescript-eslint/no-unused-vars
        }),

        setAllGoogleSearch: assign({
          showLoadMoreButtonAll: (_, event) =>
            (event as DoneInvokeEvent<SearchGoogleData>).data?.queries?.nextPage !== undefined,
          allSearchData: (_, event) => {
            return ((event as DoneInvokeEvent<SearchGoogleData>).data.items as SearchGoogleDataItems[]) ?? [];
          },
        }),
        setAllGoogleSearchOnLoadMore: assign({
          allSearchData: (ctx, event) => {
            return [
              ...ctx.allSearchData!,
              ...(((event as DoneInvokeEvent<SearchGoogleData>).data.items as SearchGoogleDataItems[]) ?? []),
            ];
          },
        }),
        setPageInputForGoogleSearch: assign({
          pageNumberGoogleSearch: (ctx) => ctx.pageNumberGoogleSearch + 1,
        }),
        resetPageInputForGoogleSearch: assign({
          pageNumberGoogleSearch: (_, event) => 0, // eslint-disable-line @typescript-eslint/no-unused-vars
        }),

        setAllTags: assign({
          allTags: (_, event) => (event as DoneInvokeEvent<GetSearchTags_listTags_tags[]>).data,
        }),
        setSelectedTags: assign({
          selectedTags: (_, event) => (event as DoneInvokeEvent<GetSearchTags_listTags_tags[]>).data,
        }),

        removeTag: assign({
          filterInput: (ctx, event) => ctx.filterInput.filter((id) => id !== (event as RemoveTagEvent).tag.id),
          selectedTags: (ctx, event) =>
            ctx.selectedTags.filter((tag) => tag.id !== (event as RemoveTagEvent).tag.id),
        }),

        removeCooktimeRangeTag: assign({
          selectedCookTimeLimit: (ctx, event) =>
            ctx.selectedCookTimeLimit.filter((tag) => tag.id !== (event as RemoveCooktimeRangeTagEvent).tag.id),
        }),

        removeSatietyRangeTag: assign({
          selectedSatietyRange: (ctx, event) =>
            ctx.selectedSatietyRange.filter((tag) => tag.id !== (event as RemoveSatietyRangeTagEvent).tag.id),
        }),

        addTag: assign({
          filterInput: (ctx, event) => [...ctx.filterInput, (event as AddTagEvent).tag.id],
          selectedTags: (ctx, event) => [...ctx.selectedTags, (event as AddTagEvent).tag],
        }),

        setCookTimeLimit: assign({
          selectedCookTimeLimit: (_, event) => (event as ChangeCookTimeLimitEvent).selectedCookTimeRange,
        }),

        setSatietyScoreRange: assign({
          selectedSatietyRange: (_, event) => (event as SetSatietyScoreRangeEvent).selectedSatietyRange,
        }),

        clearSelectedTags: assign({
          filterInput: (_, event) => [], // eslint-disable-line @typescript-eslint/no-unused-vars
          selectedTags: (_, event) => [], // eslint-disable-line @typescript-eslint/no-unused-vars
          selectedCookTimeLimit: (_, event) => [], // eslint-disable-line @typescript-eslint/no-unused-vars
          selectedSatietyRange: (_, event) => [], // eslint-disable-line @typescript-eslint/no-unused-vars
        }),
      },
      services: {
        searchRecipes: async (ctx) => {
          try {
            const { data } = await client.query({
              query: GetRecipesSearch,
              variables: {
                page: ctx.pageNumberRecipesSearch,
                pageSize: 12,
                tagFilters: ctx.filterInput,
                cookTimeMinutesRange:
                  ctx.selectedCookTimeLimit.length > 0
                    ? getMinMaxValue(ctx.selectedCookTimeLimit)
                    : { min: null, max: null },
                satietyScoreRange:
                  ctx.selectedSatietyRange.length > 0
                    ? getMinMaxValue(ctx.selectedSatietyRange)
                    : { min: 0, max: 100 },
                query: ctx.searchQuery !== "" ? ctx.searchQuery : null,
              },
            });
            return data.listRecipes;
          } catch (err: any) {
            throw new Error(err);
          }
        },
        searchMealPlans: async (ctx) => {
          try {
            const { data } = await client.query({
              query: GetMealPlansSearch,
              variables: {
                page: ctx.pageNumberMealplanSearch,
                pageSize: 12,
                query: ctx.searchQuery !== "" ? ctx.searchQuery : null,
              },
            });
            return data.listMealplans;
          } catch (err: any) {
            throw new Error(err);
          }
        },
        searchGoogle: async (ctx) => {
          const searchEngineId = getEngineId(ctx.locale);
          const searchAPI = `https://www.googleapis.com/customsearch/v1/siterestrict?key=${
            process.env.GATSBY_GOOGLE_SEARCH_API_KEY
          }&cx=${searchEngineId}&q=${ctx.searchQuery !== "" ? ctx.searchQuery : "*"}&start=${
            ctx.pageNumberGoogleSearch * 10 + 1
          }&gl=${ctx.locale}&imgSize=medium&safe=active`;
          try {
            const res = await fetch(searchAPI);
            const data = await res.json();
            return data;
          } catch (err: any) {
            throw new Error(err);
          }
        },
        getAllTags: async () => {
          try {
            const { data } = await client.query({
              query: GetSearchTagsQuery,
            });
            const allTags = [...data.listTags.tags, ...data.pageTwo.tags].filter(
              (tag) => tag?.type !== "mealplan_type"
            );
            return allTags;
          } catch (err: any) {
            throw new Error(err);
          }
        },
      },
    }
  );
};
