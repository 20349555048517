import React, { useContext } from "react";
import { useSelector } from "@xstate/react";
import { SearchPageStateContext } from "../SearchPageStateProvider";
import RecipesTab from "./RecipesTab";
import MealplansTab from "./MealplansTab";
import AllTab from "./AllTab";
import { SearchMachineState } from "../types";
import Loading from "../../../components/Loading/Loading";

const recipesViewSelector = (state: SearchMachineState) => state.matches("recipes.recipeView");
const mealplansViewSelector = (state: SearchMachineState) => state.matches("mealplans.mealplanView");
const allViewSelector = (state: SearchMachineState) => state.matches("all.allView");

const recipesLoadingSelector = (state: SearchMachineState) => state.matches("recipes.loadSearchRecipes");
const mealplansLoadingSelector = (state: SearchMachineState) => state.matches("mealplans.loadMealPlan");
const allLoadingSelector = (state: SearchMachineState) => state.matches("all.loadGoogleSearch");

const SearchResult = () => {
  const { service } = useContext(SearchPageStateContext);

  const isRecipesView = useSelector(service, recipesViewSelector);
  const isMealPlanView = useSelector(service, mealplansViewSelector);
  const isAllView = useSelector(service, allViewSelector);

  const isRecipesLoading = useSelector(service, recipesLoadingSelector);
  const isMealplansLoading = useSelector(service, mealplansLoadingSelector);
  const isAllLoading = useSelector(service, allLoadingSelector);

  const showLoading = isRecipesLoading || isMealplansLoading || isAllLoading;

  return (
    <>
      {showLoading && <Loading />}
      {isRecipesView && <RecipesTab />}
      {isMealPlanView && <MealplansTab />}
      {isAllView && <AllTab />}
    </>
  );
};

export default SearchResult;
