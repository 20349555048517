import React from "react";
import { IconProps } from "./types";

export function CloseIconBg({ className }: IconProps) {
  return (
    <svg className={className} width="25" height="25" viewBox="0 0 25 25" fill="none">
      <circle cx="12.1914" cy="12.6249" r="12" fill="currentColor" />
      <path
        d="M17.4414 16.8174L16.3839 17.8749L12.1914 13.6824L7.99891 17.8749L6.94141 16.8174L11.1339 12.6249L6.94141 8.43238L7.99891 7.37488L12.1914 11.5674L16.3839 7.37488L17.4414 8.43238L13.2489 12.6249L17.4414 16.8174Z"
        fill="white"
      />
    </svg>
  );
}
