import { useActor, useSelector } from "@xstate/react";
import React, { useCallback, useContext } from "react";
import { StringParam, useQueryParam } from "use-query-params";
import { ErrorEmojicon } from "../../../icons/ErrorEmojicon";
import { SearchPageStateContext } from "../SearchPageStateProvider";
import { useSearchTranslations } from "../translations";
import { SearchMachineState } from "../types";

type Props = {
  message: string;
};

const recipeViewSelector = (state: SearchMachineState) => state.matches("recipes.recipeView.idle");
const mealplansViewSelector = (state: SearchMachineState) => state.matches("mealplans.mealplanView.idle");

const ErrorMessage = ({ message }: Props) => {
  const tt = useSearchTranslations();
  const { service } = useContext(SearchPageStateContext);
  const [, send] = useActor(service);
  const isRecipeView = useSelector(service, recipeViewSelector);
  const isMealplansView = useSelector(service, mealplansViewSelector);
  const [, setParam] = useQueryParam("t", StringParam);

  const handleClick = useCallback(() => {
    send({ type: "SHOW_ALL_TAB", activeTab: "all" });
    setParam("all");
  }, [send, setParam]);

  return (
    <div className="w-9/12 flex flex-col items-center mx-auto">
      <div>
        <ErrorEmojicon className="text-light-grey" />
      </div>
      <div className="text-center">
        <p className="m-0">{message}</p>
        {(isRecipeView || isMealplansView) && (
          <button
            onClick={handleClick}
            className="px-8 mt-5 py-3 bg-bluegrey rounded-full font-medium lg:hover:opacity-75"
          >
            {tt.searchAll}
          </button>
        )}
      </div>
    </div>
  );
};

export default ErrorMessage;
